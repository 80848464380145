import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const companyName = 'Coldtap Australia PTY LTD';
const url = 'processr.io';
const email = 'hello@processr.io';

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />

    <div className="standalone-page container">    
    <h1>Privacy Policy</h1>

<p>This following document sets forth the Privacy Policy for the { companyName } website, <em>{ url }</em> (the Site).</p>
    <p>{ companyName } is committed to providing you with the best possible customer service experience. { companyName } is bound by the Australian Privacy Act 1988 (Privacy Act), which sets out a number of principles concerning the privacy of individuals.</p>
    <h2 id="toc-collection-of-your-personal-information">Collection of your personal information</h2>
    <p>There are many aspects of the site which can be viewed without providing personal information, however, for access to future { companyName } customer support features you are required to submit personally identifiable information. This may include but not limited to a unique username and password, or provide sensitive information in the recovery of your lost password.</p>
    <h2 id="toc-sharing-of-your-personal-information">Sharing of your personal information</h2>
    <p>We may occasionally hire other companies to provide services on our behalf, including but not limited to handling customer support enquiries, processing transactions or customer freight shipping. Those companies will be permitted to obtain only the personal information they need to deliver the service. { companyName } takes reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.</p>
    <h2 id="toc-use-of-your-personal-information">Use of your personal information</h2>
    <p>For each visitor to reach the Site, we expressively collect the following non-personally identifiable information, including but not limited to browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalised content to you while you are at this Site.</p>
    <p>From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future, we will use for these new purposes only data collected from the time of the policy change and forward.</p>
    <h2>Website analytics</h2>
    <p>To improve your experience on our site, we may use cookies. Cookies are an industry standard and most major web sites use them. A cookie is a small text file that our site may place on your computer as a tool to remember your preferences. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website.</p>
    <p>Our website may contain links to other websites. Please be aware that we are not responsible for the privacy practices of such other sites. When you go to other websites from here, we advise you to be aware and read their privacy policy.</p>
    <p>Our website uses Google Analytics, a service which transmits website traffic data to Google servers in the United States. Google Analytics does not identify individual users or associate your IP address with any other data held by Google. We use reports provided by Google Analytics to help us understand website traffic and webpage usage.</p>
    <p>By using this website, you consent to the processing of data about you by Google in the manner described in <a href="http://www.google.com/policies/privacy/">Google's Privacy Policy</a> and for the purposes set out above. You can opt out of Google Analytics if you disable or refuse the cookie, disable JavaScript, or <a href="https://tools.google.com/dlpage/gaoptout">use the opt-out service provided by Google</a>.</p>
    <p><em>{ url }</em> also uses interfaces with social media sites such as Facebook, LinkedIn, Twitter and others. If you choose to "like" or "share" information from this website through these services, you should review the privacy policy of that service. If you are a member of a social media site, the interfaces may allow the social media site to connect your visits to this site with other Personal Information.</p>
    <h3 id="toc-changes-to-this-privacy-policy">Changes to this Privacy Policy</h3>
    <p>{ companyName } reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.</p>
    <h3 id="toc-accessing-your-personal-information">Accessing Your Personal Information</h3>
    <p>You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. { companyName } reserves the right to charge a fee for searching for, and providing access to, your information on a per request basis.</p>
    <h3 id="toc-contacting-us">Contacting us</h3>
    <p>{ companyName } welcomes your comments regarding this Privacy Policy. If you have any questions about this Privacy Policy and would like further information, please contact us by any of the following means.</p>
    <p>Post:<br />
    Attn: Privacy Policy<br />
    { companyName }<br />
    PO Box 443<br />
    Strathfieldsaye, VIC 3551,<br />
    AUSTRALIA</p>
    <p>E-mail: <a href={'mailto:' + email + '?subject=Privacy Policy'}>{ email }</a> with subject: Privacy Policy</p>
</div>

  </Layout>
)

export default PrivacyPolicy